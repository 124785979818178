import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {

  const handleIn = (e) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.width = "2rem"
    cursor.style.height = "2rem"
  }

  const handleOut = (e) => {
    setStyle("#101010")
    const cursor = document.querySelector(".cursor")
    cursor.style.width = "1rem"
    cursor.style.height = "1rem"
  }

  const [background, setBackground] = useState("linear-gradient(90deg,#101010,#101010)");

  const setStyle = (background) => {
    setBackground(background);
  };

  return (
  <Layout bgcolor={background}>
    <SEO title="Diego Pavan" />
      <div
        onMouseEnter={() => setStyle("#112BFC")} 
        onMouseOver={handleIn}
        onFocus={ () => void 0 }
        onMouseLeave={handleOut}
        role="presentation"
      >
        <h2>Fabrick</h2>
        <p>Martech & Art director</p>
        <p>April 2024 - Present</p>
      </div>
      <div
        onMouseEnter={() => setStyle("#0091E5")}
        onFocus={ () => void 0 }
        onMouseOver={handleIn}
        onMouseLeave={handleOut}
        role="presentation"      
      >
        <h2>Axerve</h2>
        <p>Art Director</p>
        <p>Feb 2018 - Mar 2024</p>
      </div>
  </Layout>
)
}

export default IndexPage
